import { createSlice } from '@reduxjs/toolkit';

export const salarySlice = createSlice({
  name: 'salaryState',
  initialState: {
    formData: {},
    originalFormData: {},
    errors: {},
  },
  reducers: {
    salaryInputChange: (state, action) => {
      const newState = state;
      const { name, value } = action.payload || {};

      newState.formData[name] = value;
    },
    updateFormApi: (state, action) => {
      const newState = state;

      newState.formData = action.payload || {};
      newState.originalFormData = action.payload || {};
    },
  },
});

export const originalSalaryEditFormData = (state) => state.salaryState;
export const { salaryInputChange, updateFormApi } = salarySlice.actions;
export default salarySlice.reducer;
