import { createSlice } from '@reduxjs/toolkit';
import { generateUniqueId, isString } from '../../../Utils/commonValidation';
import initialState from './initialState';

export const EstablishmentSlice = createSlice({
  name: 'establishment',
  initialState,
  reducers: {
    resetAllStep: (state) => {
      const newState = state;
      newState.formData = initialState.formData;
    },
    addDynamicallyInputs: (state, action) => {
      const { stepName, value, name } = action.payload || {};
      const newState = state;
      newState.formData[stepName][name][state.formData[stepName][name].length] =
        value;
    },
    removeDynamicallyInputs: (state, action) => {
      const { stepName, IndexValue, name } = action.payload || {};
      const newState = state;

      newState.formData[stepName][name] = state.formData[stepName][name].filter(
        (item, index) => index !== IndexValue,
      );
    },
    editDynamicallyInputs: (state, action) => {
      const { stepName, value, index, fieldName, inputName } =
        action.payload || {};
      const newState = state;

      newState.formData[stepName][fieldName][index][inputName] = isString(value)
        ? value?.trimStart()
        : value;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
    },
    setAllSTepFromAPI: (state, action) => {
      const newState = state;
      if (action?.payload) {
        const {
          Esttmaster,
          CTCmaster,
          id,
          ownershipDeatils,
          address,
          ...stepOneData
        } = action?.payload || {};

        const leaveInfo = {
          isClEdit: Number(CTCmaster?.leaveStatus?.cl) > 0,
          isPlEdit: Number(CTCmaster?.leaveStatus?.pl) > 0,
          isMlEdit: Number(CTCmaster?.leaveStatus?.ml) > 0,
        };
        newState.formData = {
          estblshId: id,
          stepOne: {
            ...initialState?.formData?.stepOne,
            ...stepOneData,
            image: action?.payload?.estImage ? action?.payload?.estImage : {},
            ownershipDeatils: ownershipDeatils?.length
              ? ownershipDeatils
              : initialState?.formData?.stepOne.ownershipDeatils,
            address: address || initialState?.formData?.stepOne.address,
          },
          stepTwo: !Esttmaster
            ? initialState?.formData?.stepTwo
            : {
                ...initialState?.formData?.stepTwo,
                ...Esttmaster,

                address: {
                  ...initialState?.formData?.stepTwo?.address,
                  ...Esttmaster?.address,
                },
                esiCoverageDate: Esttmaster?.esiCoverageDate,
                epfCoverageDate: Esttmaster?.epfCoverageDate,
                gratuityCoverageDate: Esttmaster?.gratuityCoverageDate,
                epfStatusAddress: {
                  ...initialState?.formData?.stepTwo?.epfStatusAddress,
                  ...Esttmaster?.epfStatusAddress,
                },
              },
          stepThree: {
            ...initialState?.formData?.stepThree,

            stepThreeErrors: {},
            bankDetails: Array.isArray(CTCmaster?.bankDetails)
              ? CTCmaster?.bankDetails
              : [
                  {
                    ...CTCmaster?.bankDetails,
                  },
                ],
            allowanceInfo: (CTCmaster?.allowanceInfo || [])?.map((it) => ({
              ...it,
              uniqueId: generateUniqueId(),
            })),
            leaveStatus: {
              ...initialState?.formData?.stepThree?.leaveStatus,
              ...CTCmaster?.leaveStatus,
            },
            salaryEmpEnable: !!CTCmaster?.salaryEmpEnable,
            wagesEmpEnable: !!CTCmaster?.wagesEmpEnable,
            wagesEmpEnablePermanentlyDisabled: !!CTCmaster?.wagesEmpEnable,
            salaryEmpEnablePermanentlyDisabled: !!CTCmaster?.salaryEmpEnable,

            bonusData: {
              ...initialState?.formData?.stepThree?.bonusData,
              ...CTCmaster?.bonusData,
            },
            salary_type:
              CTCmaster?.salary_type === 'Fixed Date'
                ? 'Fixed Date'
                : 'Monthly',
          },
          stepFour: {
            stepFourErrors: {},
            leaveStatus: {
              ...initialState?.formData?.stepThree?.leaveStatus,
              ...CTCmaster?.leaveStatus,
              ...leaveInfo,

              leave_type:
                CTCmaster?.leaveStatus?.leave_type || 'Financial Year',

              carry_forward_update_date: CTCmaster?.carry_forward_update_date,
            },
            holidayInfo: CTCmaster?.holidayInfo,
          },
        };
      }
    },
    isFormSubmit: (state, action) => {
      const newState = state;
      newState.isFormSubmit = true;
    },
    SetEstblshId: (state, action) => {
      const newState = state;

      newState.formData.estblshId = action.payload;
    },
    HandleError: (state, action) => {
      const newState = state;

      if (action.payload.step === 0) {
        newState.formData.stepOne.stepOneErrors = action.payload.value;
      }
      if (action.payload.step === 1) {
        newState.formData.stepTwo.stepTwoErrors = action.payload.value;
      }
      if (action.payload.step === 2) {
        newState.formData.stepThree.stepThreeErrors = action.payload.value;
      }
    },
    ////////////////////SETP ONE START/////////////////////

    imgUpload: (state, action) => {
      const newState = state;
      newState.formData.stepOne.image = action.payload;
    },
    InputChangeStep1: (state, action) => {
      const newState = state;

      const { name, value } = action.payload || {};

      newState.formData.stepOne[name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.isFormSubmit = false;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
    },
    InputChangeStep1AllField: (state, action) => {
      const newState = state;
      newState.isFormSubmit = false;
      const allFieldData = action.payload || {};
      newState.formData.stepOne = allFieldData;
    },

    InputChangeStep1Address: (state, action) => {
      const newState = state;

      const { name, value } = action.payload || {};

      newState.formData.stepOne.address[name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.isFormSubmit = false;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
    },

    InputChangeStep1actsadd: (state, action) => {
      const newState = state;

      newState.formData.stepOne.acts[newState.formData.stepOne.acts.length] = {
        regNo: '',
        actName: '',
      };
    },

    InputChangeStep1actsRemove: (state, action) => {
      const newState = state;

      newState.formData.stepOne.acts = newState.formData.stepOne.acts.filter(
        (item, index) => index !== action.payload,
      );
    },
    InputChangeStep1actsEdit: (state, action) => {
      const newState = state;

      const { name, value, index } = action.payload || {};

      newState.formData.stepOne.acts[index][name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.isFormSubmit = false;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
    },
    Step1Branchadd: (state, action) => {
      const newState = state;

      newState.formData.stepOne.branchDetails.branches[
        state.formData.stepOne.branchDetails.branches.length
      ] = {
        branchName: '',
        branchId: '',
        streetAddress: '',
        address: '',
        description: '',
      };
    },

    Step1BranchRemove: (state, action) => {
      const newState = state;

      newState.formData.stepOne.branchDetails.branches =
        state.formData.stepOne.branchDetails.branches.filter(
          (item, index) => index !== action.payload,
        );
    },
    InputChangeStep1BranchEdit: (state, action) => {
      const newState = state;

      const { name, value, index } = action.payload || {};

      newState.formData.stepOne.branchDetails.branches[index][name] = isString(
        value,
      )
        ? value?.trimStart()
        : value;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    Step1OwnerAdd: (state, action) => {
      const newState = state;

      newState.formData.stepOne.ownershipDeatils[
        state.formData.stepOne.ownershipDeatils.length
      ] = {
        branchName: '',
        branchId: '',
        streetAddress: '',
        address: '',
        description: '',
      };
    },

    Step1OwnerRemove: (state, action) => {
      const newState = state;
      newState.formData.stepOne.ownershipDeatils =
        state.formData.stepOne.ownershipDeatils.filter(
          (item, index) => index !== action.payload,
        );
    },
    InputChangeStep1OwnerEdit: (state, action) => {
      const newState = state;

      const { name, value, index } = action.payload || {};

      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};

      newState.formData.stepOne.ownershipDeatils[index][name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.isFormSubmit = false;
    },
    InputChangeStep1OwnerAllField: (state, action) => {
      const newState = state;
      const allFieldData = action.payload || {};
      newState.formData.stepOne.ownershipDeatils = allFieldData;
    },
    updateStep1AllFieldByHookFrom: (state, action) => {
      const newState = state;
      const allFieldData = action.payload || {};
      newState.formData.stepOne = allFieldData;
    },

    ////////////////////SETP ONE END/////////////////////

    ////////////////////SETP TWO START/////////////////////

    InputChangeStep2EpfStatusAddress: (state, action) => {
      const { name, value } = action.payload;

      state.formData.stepTwo.epfStatusAddress[name] = value;
      state.formData.stepOne.stepOneErrors = {};
      state.formData.stepTwo.stepTwoErrors = {};
      state.formData.stepThree.stepThreeErrors = {};
      state.isFormSubmit = false;
    },
    InputChangeStep2ADDRESS: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;

      newState.formData.stepTwo.address[name] = value;

      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    InputChangeStep2: (state, action) => {
      const { name, value } = action.payload || {};
      const newState = state;
      newState.formData.stepTwo[name] = isString(value)
        ? value?.trimStart()
        : value;
      name === 'is_esic' && value === false
        ? (newState.formData.stepTwo.esiNo = null)
        : (newState.formData.stepTwo[name] = isString(value)
            ? value?.trimStart()
            : value);
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },

    ////////////////////SETP TWO END/////////////////////

    step3AllowenceAdd: (state, action) => {
      const newState = state;

      newState.formData.stepThree.allowanceInfo[
        state.formData.stepThree.allowanceInfo.length
      ] = {
        allowance: '',
        allowance_type: 'Fixed',
        amount: '',
        no_work_pay: 'No',
        esi_wages: 'false',
        epf_wages: 'false',
        isSubmit: 'false',
        slug: '',
      };
    },

    step3AllowenceRemove: (state, action) => {
      const newState = state;

      newState.formData.stepThree.allowanceInfo =
        state.formData.stepThree.allowanceInfo.filter(
          (item, index) => index !== action.payload,
        );
    },

    InputChangestep3AllowenceEdit: (state, action) => {
      const newState = state;

      const { name, value, index } = action.payload || {};
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};

      newState.formData.stepThree.allowanceInfo[index][name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.isFormSubmit = false;
    },
    step3AllowenceAutoAddFill: (state, action) => {
      const newState = state;

      const { value, index } = action.payload || {};

      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};

      newState.formData.stepThree.allowanceInfo[index] = value;
      newState.isFormSubmit = false;
    },

    InputChangeStep3: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      newState.formData.stepThree[name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    bankDetailsStep3: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      newState.formData.stepThree.bankDetails[name] = isString(value)
        ? value?.trimStart()
        : value;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    leaveStatusStep3: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      if (action.payload.type === 'checkbox') {
        value === 'true'
          ? newState.formData.stepThree.leaveStatus.weekly_close.push(name)
          : newState.formData.stepThree.leaveStatus.weekly_close.splice(
              newState.formData.stepThree.leaveStatus.weekly_close.indexOf(
                name,
              ),
              1,
            );
      } else if (state.formData.stepThree.leaveStatus) {
        newState.formData.stepThree.leaveStatus[name] = isString(value)
          ? value?.trimStart()
          : value;
      }
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    bonusDataStep3: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      newState.formData.stepThree.bonusData[name] = isString(value)
        ? value?.trimStart()
        : value;

      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },
    payableleaveStatusStep3: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      if (action.payload.type === 'checkbox') {
        newState.formData.stepThree.leaveStatus.is_payable = isString(value)
          ? value?.trimStart()
          : value;
      }
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.isFormSubmit = false;
    },

    ////////////////////SETP Four Start/////////////////////

    leaveStatusStep4: (state, action) => {
      const newState = state;

      const { name, value } = action.payload;
      if (action.payload.type === 'checkbox' && name !== 'is_payable') {
        value === 'true'
          ? state.formData.stepFour.leaveStatus.weekly_close.push(name)
          : state.formData.stepFour.leaveStatus.weekly_close.splice(
              state.formData.stepFour.leaveStatus.weekly_close.indexOf(name),
              1,
            );
      }
      newState.formData.stepFour.leaveStatus[name] = isString(value)
        ? value?.trimStart()
        : value;

      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};

      newState.formData.stepFour.stepFourErrors = {};
      newState.isFormSubmit = false;
    },

    holidayInfoStep4: (state, action) => {
      const newState = state;

      newState.formData.stepFour.holidayInfo = action.payload;
      newState.formData.stepOne.stepOneErrors = {};
      newState.formData.stepTwo.stepTwoErrors = {};
      newState.formData.stepThree.stepThreeErrors = {};
      newState.formData.stepFour.stepFourErrors = {};

      newState.isFormSubmit = false;
    },
    ////////////////////SETP Four End/////////////////////
  },
});

export const {
  isFormSubmit,
  InputChangeStep1,
  InputChangeStep1Address,
  InputChangeStep2,
  InputChangeStep2ADDRESS,
  InputChangeStep2EpfStatusAddress,
  InputChangeStep3,
  InputChangeStep3Allowance_details,
  InputChangeStep1actsadd,
  InputChangeStep1actsRemove,
  InputChangeStep1actsEdit,
  HandleError,
  InputChangeStep1BranchEdit,
  Step1Branchadd,
  Step1BranchRemove,
  InputChangeStep1OwnerEdit,
  Step1OwnerRemove,
  Step1OwnerAdd,
  SetEstblshId,
  Step3weekly_close,
  bankDetailsStep3,
  InputChangestep3AllowenceEdit,
  step3AllowenceRemove,
  step3AllowenceAdd,
  setAllSTepFromAPI,
  resetAllStep,
  leaveStatusStep3,
  bonusDataStep3,
  payableleaveStatusStep3,
  imgUpload,
  leaveStatusStep4,
  holidayInfoStep4,
  addDynamicallyInputs,
  removeDynamicallyInputs,
  editDynamicallyInputs,
  InputChangeStep1AllField,
  InputChangeStep1OwnerAllField,
  updateStep1AllFieldByHookFrom,
  step3AllowenceAutoAddFill,
} = EstablishmentSlice.actions;
export default EstablishmentSlice.reducer;
