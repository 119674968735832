/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
export const tagTypesList = [
  'showEstablishment',
  'getBranch',
  'Designation',
  'Establishment',
  'Employee',
  'Branch',
  'Kyc',
  'HolidayList',
  'EmployeeDashboard',
  'ShiftList',
  'salaryReport',
  'epfReport',
  'esiReport',
  'payslipReport',
  'LoanReport',
  'pTaxReport',
  'department',
  'Allowance',
  'EstablishmentList',
  'SalaryList',
  'Attendance',
  'AddEmployee',
  'HeaderEstablishment',
  'UserInfo',
  'SALARYREPORTS',
  // 'salaryUpdate',
];
