/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import GetCategorySlice from './GetCategortSlice';
import GetdepertMentSlice from './GetdepertMentSlice';
import GlobalSlice from './GlobalSlice';
import ImportDataSlice from './ImportDataSlice';
import EstablishmentSlice from './features/Establishment/EstablishmentSlice';
import globalApiSlice from './features/api/globalApiSlice';
import attendanceSlice from './features/attendance/attendanceSlice';
import employeeReducer from './features/employee/employeeSlice';
import salarySlice from './features/salary/salarySlice';
import authSliceReducer from './features/user/authSlice';

import snakbarReducer from './features/snakbar/snakbarSlice';

const store = configureStore({
  reducer: {
    GlobalSlice: GlobalSlice,
    GetdepertMentSlice: GetdepertMentSlice,
    GetCategorySlice: GetCategorySlice,
    establisment: EstablishmentSlice,
    employee: employeeReducer,
    auth: authSliceReducer,
    importData: ImportDataSlice,
    salaryState: salarySlice,
    attendance: attendanceSlice,

    snakbar: snakbarReducer,
    [globalApiSlice.reducerPath]: globalApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(globalApiSlice.middleware),
});

export default store;
