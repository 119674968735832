/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';

export const GlobalSlice = createSlice({
  name: 'GlobalSlice',
  initialState: {
    selectArr: [],
    sortDir: 'ASC',
    snackbarObj: {
      isopen: false,
      messege: 'hello world',
    },
    currentEmployee: {},
    CurrentEstablishment: {},

    pathListForEstHeaderNotSetFromHeader: ['/employee/edit'],
    selectedRows: [],
    // selectedRows: localStorage?.getItem('selectedRows') || [],
    selectedRowsAllData: [],
    estSessionListByState: [],
    tableRecordValues: [78, 25, 50, 100],
    defaultRecordValue: 25,

    estabLishList: undefined,
    isEstPresent: false,
    currentUrlPath: null,
    importSelectedFields: [],
  },

  reducers: {
    updateGlobalState: (state, action) => {
      const newState = state;
      newState.CurrentEstablishment = action.payload;
    },
    setImportSelectedFields: (state, action) => {
      const newState = state;
      newState.importSelectedFields = action.payload;
    },
    updateImportSelectedFields: (state, action) => {
      state.importSelectedFields = [
        ...state.importSelectedFields,
        action.payload,
      ];
    },
    setCurrentEst: (state, action) => {
      const newState = state;
      newState.CurrentEstablishment = newState.estabLishList?.find?.(
        (est) => est?.id === action.payload,
      );
    },

    setIsEstPresent: (state, action) => {
      const newState = state;
      newState.isEstPresent = action.payload;
    },
    selectAllTable: (state, action) => {
      const newState = state;
      newState.selectedRows = action.payload;
      // localStorage.setItem('selectedRows', [state?.selectedRows]);
    },
    resetSelectedRows: (state) => {
      const newState = state;
      newState.selectedRows = [];
    },
    setrtSectedRowsAllData: (state, action) => {
      const newState = state;
      newState.selectedRowsAllData = action.payload;
    },
    setEstSessionListByState: (state, action) => {
      const newState = state;
      newState.estSessionListByState = action.payload;
    },

    tableSort: (state, action) => {
      const newState = state;
      newState.sortDir = '';
    },
    updateAttendance: (state, action) => {
      const newState = state;
      newState.currentEmployee = action.payload;
    },
    setEstabLishList: (state, action) => {
      const newState = state;
      newState.estabLishList = action.payload;
    },
    setDefaultRecordValue: (state, action) => {
      const newState = state;
      newState.defaultRecordValue = action.payload;
    },
    setCurrentUrlPath: (state, action) => {
      const newState = state;
      newState.currentUrlPath = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGlobalState,

  selectAllTable,
  resetSelectedRows,
  updateAttendance,
  setCurrentEst,
  setEstabLishList,
  setrtSectedRowsAllData,
  setEstSessionListByState,

  setIsEstPresent,
  setDefaultRecordValue,
  setCurrentUrlPath,
  updateImportSelectedFields,
  setImportSelectedFields,
} = GlobalSlice.actions;

export const defaultRecordValue = (state) =>
  state.GlobalSlice.defaultRecordValue;
export const tableRecordValues = (state) => state.GlobalSlice.tableRecordValues;

export const isEstPresent = (state) => state.GlobalSlice.isEstPresent;
export const selectImportSelectedFields = (state) =>
  state.GlobalSlice.importSelectedFields;

export default GlobalSlice.reducer;
