/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: {
    chooseDate: { year: undefined, month: undefined },
    importData: [],
  },
  reducers: {
    setDate: (state, action) => {
      const newState = state;
      const { name, value } = action.payload;

      newState.chooseDate[name] = value;
    },
    resetDate: (state, action) => {
      const newState = state;
      newState.chooseDate = { year: undefined, month: undefined };
    },
    setImportAttendanceData: (state, action) => {
      state.importData = action.payload;
    },
    resetImportAttendanceData: (state, action) => {
      state.importData = [];
      state.chooseDate = { year: undefined, month: undefined };
    },
  },
});

export const {
  setDate,
  resetDate,
  setImportAttendanceData,
  resetImportAttendanceData,
} = attendanceSlice.actions;
export default attendanceSlice.reducer;
export const attendanceImportData = (state) => state.attendance.importData;
