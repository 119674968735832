/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeData: {
    allwancedata: null,
  },
  currentTab: 0,
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: initialState,
  reducers: {
    allwanceEdit: (state, action) => {
      const { value, index } = action.payload;

      // eslint-disable-next-line no-param-reassign
      state.employeeData.allwancedata[index] = value;
    },
    updateAllwanceApiCall: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.employeeData.allwancedata = action.payload;
    },
    updateCurrentTab: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentTab = action.payload;
    },
    resetEmployeeState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.currentTab = initialState.currentTab;
      state.employeeData = initialState.employeeData;
    },
  },
});

export const {
  allwanceEdit,
  updateAllwanceApiCall,
  updateCurrentTab,
  resetEmployeeState,
} = employeeSlice.actions;
export const employeeAllwance = (state) =>
  state.employee.employeeData.allwancedata;
export const employeeCurrentTab = (state) => state.employee.currentTab;
export default employeeSlice.reducer;
