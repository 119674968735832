/* eslint-disable prettier/prettier */
/* eslint-disable no-prototype-builtins */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApihelperFunction } from '../API/ApiHelperFunction';

const initialState = {
  loading: false,
  response: null,
  excelFileHeadings: null,
  validationErrorFrontend: null,
  validationErrorBackend: null,
  fileDetails: null,
  messages: null,
  dbHeadings: null,
  isError: false,
};
export const importData = createAsyncThunk('import_data', async (data) => {
  const formData = new FormData();
  formData.append('templateType', data.templateType);
  formData.append('file', data.file[0]);
  formData.append('delimeter', data.delimeter);
  formData.append('estblshId', data.estblshId);
  formData.append('fileEncoding', data.fileEncoding);

  const response = ApihelperFunction({
    urlPath: `/importExcel/readImportFile/${data.importUrlParams}`,
    method: 'POST',
    formData: formData,
    typecontent: true,
  });
  return response;
});
export const validateDbData = createAsyncThunk(
  'validate_db_data',
  async (data) => {
    const response = ApihelperFunction({
      urlPath: `/importExcel/validate`,
      method: 'POST',
      formData: data,
    });
    return response;
  },
);
export const importFile = createAsyncThunk('import_file', async (data) => {
  const response = ApihelperFunction({
    urlPath: `/importExcel/import`,
    method: 'POST',
    formData: data,
  });
  return response;
});
export const importDataSlice = createSlice({
  name: 'ImportDataSlice',
  initialState,
  reducers: {
    updateExcelData: (state, action) => {
      const newState = state;
      newState.fileDetails = action.payload;
    },
    updateFrontendValidation: (state, action) => {
      const newState = state;

      newState.validationErrorFrontend = action.payload;
    },
    updatebackendCustomValidation: (state, action) => {
      const newState = state;

      newState.validationErrorBackend = action.payload;
    },
    resetState: (state) => {
      const newState = state;

      newState.loading = false;
      newState.response = initialState.response;
      newState.excelFileHeadings = initialState.excelFileHeadings;
      newState.dbHeadings = initialState.dbHeadings;
      newState.fileDetails = initialState.data;
      newState.validationErrorFrontend = [];
      newState.validationErrorBackend = [];
    },
    customSetState: (state, action) => {
      const newState = state;

      newState.loading = false;

      newState.loading = false;
      newState.response = action.payload;
      // for next developer
      // newState.excelFileHeadings = action.payload.excelFileHeadings

      const repalceObj = {
        CTC: 'ctcInformation.CTC',

        gurdian_name: 'personalDetails.gurdianName',
      };

      newState.excelFileHeadings = action.payload.excelFileHeadings?.map((it) =>
        repalceObj.hasOwnProperty(it) ? repalceObj[it] : it,
      );
      newState.dbHeadings = action.payload.dbHeadings;
      newState.fileDetails = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importData.pending, (state, action) => {
        const newState = state;

        newState.loading = true;
      })
      .addCase(importData.fulfilled, (state, action) => {
        const newState = state;

        newState.loading = false;
        newState.response = action.payload;
        // for next developer
        // newState.excelFileHeadings = action.payload.excelFileHeadings

        const repalceObj = {
          CTC: 'ctcInformation.CTC',

          gurdian_name: 'personalDetails.gurdianName',
        };

        newState.excelFileHeadings = action.payload.excelFileHeadings?.map(
          (it) => (repalceObj.hasOwnProperty(it) ? repalceObj[it] : it),
        );
        newState.dbHeadings = action.payload.dbHeadings;
        newState.fileDetails = action.payload.data;
      })
      .addCase(importData.rejected, (state, action) => {
        const newState = state;

        newState.loading = false;

        newState.isError = true;
      })
      .addCase(validateDbData.pending, (state, action) => {
        const newState = state;

        newState.loading = true;
      })
      .addCase(validateDbData.fulfilled, (state, action) => {
        const newState = state;

        newState.loading = false;
        newState.response = action.payload;
        newState.validationErrorBackend = action?.payload?.errors;
      })
      .addCase(validateDbData.rejected, (state, action) => {
        const newState = state;

        newState.loading = false;

        newState.isError = true;
      })
      .addCase(importFile.pending, (state, action) => {
        const newState = state;

        newState.loading = true;
      })
      .addCase(importFile.fulfilled, (state, action) => {
        const newState = state;

        newState.loading = false;
        newState.response = action.payload;
      })
      .addCase(importFile.rejected, (state, action) => {
        const newState = state;

        newState.loading = false;
        newState.isError = true;
      });
  },
});
export const {
  updateExcelData,
  updateFrontendValidation,
  resetState,
  updatebackendCustomValidation,
  customSetState,
} = importDataSlice.actions;
export default importDataSlice.reducer;
