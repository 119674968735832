/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  accessToken: undefined,
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      const newState = state;
      newState.accessToken = action.payload.accessToken;
      newState.user = action.payload.user;
    },
    userLoggedOut: (state) => {
      localStorage.removeItem('userDetails');

      Cookies.remove('establishment');
      Cookies.remove('employee');
      localStorage.removeItem('x-access-token');
      const newState = state;
      newState.accessToken = undefined;
      newState.user = undefined;

      window.location.href = '/';
    },
  },
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice.reducer;
